import React, { useEffect, useState } from "react";
import "./socialPoster.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";
import { useLocation } from "react-router-dom";
import { toPng, toJpeg } from "html-to-image";
import { useRef, useCallback } from "react";
import { Button } from "antd";
import { getQrCodeUrlForProgramUuid } from "../../utils/utils";

/**
 * @param {object} data
 * @param {string} data.businessName
 * @param {string} data.businessLogo
 * @param {string} data.programUuid
 * @param {string} data.industry
 * @param {string} data.address
 * @param {string} data.brandColor
 * @param {string} data.size
 * @param {string} data.heroImageId
 */
const openScoialPage = (data) => {
  const queryParams = new URLSearchParams(data).toString();

  const url = `/pdf?${queryParams}`;
  window.open(url, "_blank");
};

const QR_CODE_PLACEHOLDER =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IndoaXRlIi8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTIwIDIwaDYwdjYwSDIwek0zNSAzNWgzMHYzMEgzNXoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMTIwIDIwaDYwdjYwaC02MHpNMTM1IDM1aDMwdjMwaC0zMHoiLz4KICA8cGF0aCBmaWxsPSIjRThFOEU4IiBkPSJNMjAgMTIwaDYwdjYwSDIwek0zNSAxMzVoMzB2MzBIMzV6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDkwaDIwdjIwSDkwek0xMjAgOTBoMjB2MjBoLTIwek0xNTAgOTBoMjB2MjBoLTIwek05MCAxMjBoMjB2MjBIOTB6TTEyMCAxMjBoMjB2MjBoLTIwek0xNTAgMTIwaDIwdjIwaC0yMHpNOTAgMTUwaDIwdjIwSDkwek0xMjAgMTUwaDIwdjIwaC0yMHpNMTUwIDE1MGgyMHYyMGgtMjB6Ii8+CiAgPHBhdGggZmlsbD0iI0U4RThFOCIgZD0iTTkwIDIwaDIwdjIwSDkwek05MCA1MGgyMHYyMEg5MHpNMjAgOTBoMjB2MjBIMjB6TTUwIDkwaDIwdjIwSDUweiIvPgo8L3N2Zz4=";

function SocialPoster() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = useRef();

  const downloadImage = async (type) => {
    if (type === "png") {
      const dataUrl = await toPng(ref.current);
      download(dataUrl, "meed-social-poster.png");
    } else if (type === "jpeg") {
      const dataUrl = await toJpeg(ref.current);
      download(dataUrl, "meed-social-poster.jpeg");
    }
  };

  const download = (dataUrl, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataUrl;
    link.click();
  };
  const onBack = useCallback(() => {
    window.close();
  }, []);
  const data = {
    businessName: queryParams.get("businessName"),
    businessLogo: queryParams.get("businessLogo"),
    programUuid: queryParams.get("programUuid"),
    address: queryParams.get("address"),
    brandColor: queryParams.get("brandColor"),
    industry: queryParams.get("industry"),
    locations: queryParams.get("locations"),
    offer:
      "Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer ",
    heroImageId: queryParams.get("heroBannerLogo"),
  };

  const [qrCodeUrl, setQrCodeUrl] = useState(QR_CODE_PLACEHOLDER);

  useEffect(() => {
    // Fetch QR code
    const fetchQrCodeUrl = async () => {
      try {
        const url = await getQrCodeUrlForProgramUuid(
          data.programUuid,
          String(data.locations?.[0]?.id),
        );
        setQrCodeUrl(url);
      } catch (error) {
        console.error("Failed to fetch QR code:", error);
        setQrCodeUrl(QR_CODE_PLACEHOLDER);
      }
    };

    fetchQrCodeUrl();
  }, [data.programUuid, data.locations]);

  return (
    <>
      <div className="social-poster">
        <a
          className="social-poster-close-button"
          onClick={onBack}
          style={{ cursor: "pointer" }}
        >
          ← Close
        </a>
        <div
          className="social-poster-container"
          style={{ backgroundColor: "white" }}
          ref={ref}
        >
          <div className="top-left-logo">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
              alt="Coffee Place Logo"
              className="logo"
            />
          </div>
          <div className="image-mask">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.heroImageId}.jpg`}
              alt="Hero Image"
              className="hero-image"
            />
          </div>
          <div className="preview_qr">
            <img className="qr_image" src={qrCodeUrl} alt="" />
          </div>
          <div className="text-section">
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "22px",
                color: "#e06932",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              Scan for
            </p>
            <h1 className="place-name">Scan for Discount</h1>
            <p style={{ fontWeight: "bold", fontSize: "15px" }}>* T&C Apply</p>
          </div>
          <PoweredByMeed className="powered-by-meed" />
        </div>
        <div className="social-poster-buttons">
          <Button
            onClick={() => downloadImage("png")}
            className="social-poster-download-button"
          >
            Download PNG
          </Button>
          <Button
            onClick={() => downloadImage("jpeg")}
            className="social-poster-download-button"
          >
            Download JPEG
          </Button>
        </div>
      </div>
    </>
  );
}

export default SocialPoster;
